@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }

  -webkit-animation: $animations;
  -moz-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}
