.faq-page {
  // background-color: #30353e;
  background-color: $black;

  .faq-row {
    @include flex-grid-row();

    .faq-body {
      @include flex-grid-column(12);

      h1 {
        color: $darkred;
        margin-top: rem-calc(40);
        line-height: 1.2em;
      }

      ul {
        padding: rem-calc(20);
      }

      li.question {
        color: $darkred;
        font-size: rem-calc(18);
        padding: rem-calc(10);
      }

      .answer {
        margin-top: 15px;

        ul {
          padding-top: 0;
          padding-bottom: 0;

          li {
            margin-bottom: rem-calc(10);
            line-height: 1.2em;
          }
        }

        p,
        li {
          color: $white;
          font-size: rem-calc(16);
        }

        li {
          margin: rem-calc(5);
        }
      }
    }
  }
}
