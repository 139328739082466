* { box-sizing: border-box; }

/* Slideshow container */
.slideshow-section {
  padding-top: rem-calc(20);
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
}

.slideshow-container {
  background-color: $black;
  width: 100%;
  height: calc(100vh - 70px);
  top: 0;
  position: relative;
  margin: auto;
  margin-bottom: 0;

  @include breakpoint(large up) {
    height: calc(100vh);
    top: -120px;
    // margin-bottom: -120px;
  }

  :hover {
    cursor: pointer;
  }

  .slideshow-loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      width: 80px;
    }

    &.hide {
      display: none;
    }
  }
  // border: 1px solid $gray;

  .caption-row {
    @include flex-grid-row();

    border-top: 2px solid $darkred;
    height: calc(50vh + 12px);
    position: absolute;
    overflow: hidden;
    bottom: 0;
    background-color: $black;

    @include breakpoint(large up) {
      background-color: transparent;
      height: 100%;
      overflow: visible;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
    }

    align-items: center;

    @include breakpoint(large up) {
      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }

      &.left {
        justify-content: flex-start;
      }
    }

    .caption-container {
      @include flex-grid-column(12);

      height: 100%;

      @include breakpoint(large up) {
        @include flex-grid-column(5);

        height: unset;
      }

      .caption-body {
        .caption-title {
          h1 {
            color: $darkred;
            text-shadow: -1px 0 2px rgba($black, 0.7), 0 1px 2px rgba($black, 0.7), 1px 0 2px rgba($black, 0.7), 0 -1px 2px rgba($black, 0.7);
            margin-bottom: 0;
            text-align: center;
          }
        }
      }

      // background-color: rgba($white, 0.7);
      span {
        width: 100%;
        display: block;
        color: $darkred;
        font-size: rem-calc(12);

        @include breakpoint(large up) {
          font-size: rem-calc(50);
        }

        text-shadow: -1px 0 10px $black, 0 1px 10px $black, 1px 0 10px $black, 0 -1px 10px $black;

        &.startquote {
          @include breakpoint(large up) {
            margin-left: -20px;
          }

          margin-top: -10px;
          margin-bottom: 0;
        }

        &.endquote {
          text-align: right;
        }
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        color: $white;
        line-height: 1.3em;
        font-size: rem-calc(16);

        @include breakpoint(medium up) {
          font-size: rem-calc(18);
        }

        @include breakpoint(xlarge up) {
          font-size: rem-calc(22);
        }

        text-shadow: -1px 0 2px $black, 0 1px 2px $black, 1px 0 2px $black, 0 -1px 2px $black;
      }

      .quoter {
        text-align: center;

        p {
          color: $darkred;
          font-size: rem-calc(14);

          @include breakpoint(xlarge up) {
            font-size: rem-calc(24);
          }
        }
      }
      // @include breakpoint(medium) {
      //     span {
      //         font-size: rem-calc(40);
      //     }
      //     p {
      //         font-size: rem-calc(20);
      //         line-height: rem-calc(38);
      //     }
      //     .quoter p {
      //         font-size: rem-calc(18);
      //     }
      // }
      // @include breakpoint(large) {
      //     p {
      //         font-size: rem-calc(30);
      //     }
      // }
      .captionbox {
        background-color: rgba($black, 0.3);

        @include breakpoint(large up) {
          padding: rem-calc(20);
          padding-left: rem-calc(30);
          padding-right: rem-calc(30);
          box-shadow: 0 0 300px 300px rgba($black, 0.3);
        }

        p {
          line-height: 1.3em;
          font-size: rem-calc(16);

          @include breakpoint(medium up) {
            font-size: rem-calc(18);
          }

          @include breakpoint(xlarge up) {
            font-size: rem-calc(22);
          }
        }
      }
    }

    &.light {
      .captionbox {
        background-color: rgba($white, 0.5);
        box-shadow: 0 0 68px 68px rgba($white, 0.5);

        p {
          color: $black;
          text-shadow: none;
        }
      }
    }
  }
}

.mySlides {
  display: block;
  overflow: hidden;
  background: transparent;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @include breakpoint(large up) {
    background-size: cover;
    background-position: center center;
  }

  img {
    visibility: hidden;
  }
}

.myHeroSlides {
  border-bottom: 2px solid $gray;
}

.full-prev {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;

  &:hover {
    cursor: pointer;

    .prev {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

.full-next {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;

  &:hover {
    cursor: pointer;

    .next {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 100% 100% 0;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 100% 0 0 100%;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  font-size: 15px;
  padding: 0 12px;
  padding-top: rem-calc(22);
  padding-bottom: rem-calc(22);
  width: 50%;
  text-align: center;

  h1 {
    color: $white;
    font-size: rem-calc(36);
  }
}



/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  font-family: 'Cantarell', sans-serif;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 13px;
  width: 13px;
  margin: 0 2px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.slideshow-dots {
  position: absolute;
  bottom: 10px;

  @include breakpoint(large up) {
    bottom: 20px;
  }

  z-index: 1;
  width: 100%;

  .active,
  .dot:hover {
    background-color: $darkred;
  }
}

.slide {
  float: left;
  position: absolute;
  width: 100%;
  // height: calc(100vh - 300px);
  // @include breakpoint(large up) {
  //     height: 100%;
  // }
  height: 100%;
  opacity: 0;

  @include transition(opacity 950ms linear);
}

.slide-link {
  z-index: 2;
}

.slider-wrapper > .slide:first-child {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-moz-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  opacity: 0;  /* make things invisible upon start */
  -webkit-animation: fadein ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadein ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1) */
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
