
.questionnaire {
  .diffcol {
    background-color: rgba(0,0,0,0.05);
    padding: 15px 0;
    border-radius: 15px;
  }
  input, textarea {
    margin-bottom: 5px;
  }
  .row {
    margin-bottom: 10px;
  }
  .devider {
    text-align: center;
    padding: 20px;
  }
  label {
    margin: 10px 0;
  }
  .submitt-form {
    margin-top: 10px;
    text-align: center;
    button {
        margin-top: 10px;
      border: none;
      font-size: 18px;
    }
    input {
        margin-right: 5px;
    }
  }
  .custom-radio label {
    padding-left: 10px;
  }
  li {
    margin-top: 10px;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(1,1,1,0.8);
  }
  .paragraph {
      p {
          margin: 20px 0;
      }
  }
}
