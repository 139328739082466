
.contactform {
  position: relative;
  input {
    margin: 20px 0;
  }
  
  .button {
    border: none;
  }
  .submitt {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#fafafa,0.9);
  }
  .error {
    p {
      color: red;
    }
  }
}
