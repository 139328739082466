
.messagebox {
  position: fixed;
  width: 100%;
  bottom: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  .container {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    max-width: 1200px;
    box-shadow: 5px 5px 15px #000;
    a {
      color: rgba(#000, 0.7);
      font-weight: bold;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
