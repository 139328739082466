@import '~foundation-sites/scss/foundation';
@import '~foundation-sites/scss/util/util';
@import 'mixins/transition';
@import 'mixins/placeholder';
@import 'foundation/settings';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~bootstrap/dist/css/bootstrap.css';
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins/grid";
@import 'partials/header';
// @import url('https://fonts.googleapis.com/css?family=Didact+Gothic|Open+Sans:400,600,700,800|Roboto');
@include foundation-flex-classes;
@include foundation-flex-grid;
@include foundation-global-styles;
// @include foundation-forms;

$headersize: '100px';

#datepicker__dialog_ {
  .b-calendar-grid {
    padding: 20px;
  }
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  speak: none !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased !important;
  font-weight: 400;
  margin-top: rem-calc(70);
  padding-bottom: rem-calc(150);
  background-color: $gray-lighter;
  // background-color: $bgcolor;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }

  button {
    padding: 7px;

    &:hover {
      color: $white;
    }

    &:focus {
      outline: 0;
    }
  }

  p {
    font-family: 'Montserrat', sans-serif;
    speak: none !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    -webkit-font-smoothing: antialiased !important;
    text-transform: none !important;
    line-height: rem-calc(28);
    font-size: rem-calc(14);
    color: $gray;
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 46px;
    font-size: rem-calc(22);
    color: $gray-dark;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    line-height: 23px;
    font-size: rem-calc(20);
    color: $gray-dark;
  }

  h3 {
    font-size: rem-calc(18);
  }

  a {
    font-family: 'Montserrat', sans-serif;
    font-size: rem-calc(14);
  }

  // span {
  //   font-family: 'Cantarell', sans-serif;
  // }

  ul,
  ol {
    li {
      color: $gray;
      font-size: rem-calc(14);
    }
  }

  ol {
    counter-reset: item;
  }
  ol li { display: block; }

  ol li::before {
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: bold;
  }

  table {
    th,
    td {
      font-size: rem-calc(14);
    }
  }

  @include breakpoint(large up) {
    margin-top: rem-calc(122);
    padding-bottom: rem-calc(220);

    h1 {
      font-size: rem-calc(26);
    }

    h2 {
      font-size: rem-calc(24);
    }

    h3 {
      font-size: rem-calc(21);
    }

    p {
      font-size: rem-calc(16);
      line-height: 23px;
    }

    a {
      font-size: rem-calc(16);
    }

    ul,
    ol {
      li {
        font-size: rem-calc(16);
      }
    }

    table {
      th,
      td {
        font-size: rem-calc(16);
      }
    }
  }
}

.messages {
  display: none;
}

main {
  @include breakpoint(large up) {
    .page {
      padding-bottom: rem-calc(100);
    }
  }

  .breadcrumbs {
    @include flex-grid-row();

    .breadcrumbs-container {
      @include flex-grid-column(12);
    }

    ul {
      padding: 0;
      margin: 0;
      margin-top: rem-calc(10);

      li {
        display: inline-block;
        font-size: rem-calc(12);
        height: 20px;
        vertical-align: top;

        p {
          font-size: rem-calc(12);
          margin: 0;
        }

        a {
          color: $black;
          font-size: rem-calc(12);
          text-decoration: none;
          font-weight: bold;
        }
      }
    }

    @include breakpoint(large up) {
      ul {
        li {
          font-size: rem-calc(14);

          a {
            font-size: rem-calc(14);
          }

          p {
            font-size: rem-calc(14);
          }
        }
      }
    }
  }

  .button {
    text-decoration: none;
    background-color: $darkred;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: rem-calc(14);
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
      background-color: $black;
    }

    &:active {
      background-color: $darkred;
    }

    &:focus {
      background-color: $black;
    }
  }

  min-height: 100%;

  img.right {
    float: right;
    margin-left: rem-calc(20);
    margin-bottom: rem-calc(20);
    margin-top: rem-calc(20);
  }

  img.left {
    float: left;
    margin-right: rem-calc(20);
    margin-bottom: rem-calc(20);
    margin-top: rem-calc(20);
  }

  img.full-width {
    width: 100%;
  }

  @import 'partials/slideshowhero';
  @import 'partials/articleindex';
  @import 'partials/contact';

  .login-register {
    @include flex-grid-row();

    form {
      max-width: rem-calc(400);

      input[type=text],
      input[type=email],
      input[type=password] {
        all: unset;
        background: transparent;
        border: none;
        border-bottom: 1px dashed $gray;
        width: 275px;
        outline: none;
        padding: 0 0 0 0;
        font-style: italic;
        min-height: rem-calc(40);
        margin-bottom: rem-calc(20);

        &:focus {
          border-bottom: 1px dashed $darkred;
        }
      }

      .button {
        display: block;
      }

      @include placeholder {
        color: #d3d3d3;
      }
    }

    .login {
      @include flex-grid-column(12);

      a {
        color: $black;
      }
    }

    .register {
      @include flex-grid-column(12);
    }

    @include breakpoint(large up) {
      .login {
        @include flex-grid-column(6);
      }

      .register {
        @include flex-grid-column(6);
      }
    }
  }
}

.frontpage {
  main {
    margin-bottom: -140px;
  }
}

@import 'partials/frontpage';
@import 'partials/faq';
@import 'partials/article';
@import 'partials/treatments';
@import 'partials/team';
@import 'partials/services';
@import 'partials/footer';

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-moz-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  opacity: 0;  /* make things invisible upon start */
  -webkit-animation: fadein ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadein ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1) */
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fade-in.one {
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.fade-in.two {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.fade-in.three {
  -webkit-animation-delay: 1.6s;
  -moz-animation-delay: 1.6s;
  animation-delay: 1.6s;
}
