.treatments-index-page {
  background-color: $black;

  .treatments-page {
    background-color: $white;

    @include breakpoint(large up) {
      padding-bottom: rem-calc(60);
    }

    @include transition(padding-bottom 0.7s ease);

    .treatments-row {
      @include flex-grid-row();

      .treatments-container-left,
      .treatments-container-right {
        @include flex-grid-column(4);

        min-height: 100%;
        display: flex;
        align-items: center;

        ul {
          list-style: none;

          li {
            padding: rem-calc(10);

            a {
              text-decoration: none;
              color: $darkred;
              font-size: rem-calc(18);
              background-color: #30353e;

              &:hover {
                color: #30353e;
                background-color: $darkred;
              }

              &:active {
                color: $darkred !important;
              }

              &.selected {
                color: #30353e;
                background-color: $darkred;
              }
            }

            .button {
              padding: 7px;

              &:active {
                color: $darkred !important;
              }
            }
          }
        }
      }

      .treatments-container-left {
        justify-content: flex-end;

        ul {
          li {
            text-align: right;
          }
        }
      }

      .treatments-container-center {
        @include flex-grid-column(4);

        margin-top: rem-calc(40);
        display: flex;
        justify-content: center;
        align-items: center;

        .bodybox {
          position: relative;
          height: 512px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 512px;
          }

          .markerbox {
            opacity: 0;
            width: 90px;
            height: 90px;
            background-color: rgba($black, 0.5);
            border-radius: 50%;
            border: 4px solid #30353e;
            // border: 4px solid $gray-light;
            position: absolute;
            z-index: 4;

            @include transition(all 0.4s ease);

            &.whole {
              width: 100%;
              height: 95%;
              border-radius: 10px;
            }
          }
        }
      }

      .treatment-detail {
        width: 100%;
        min-height: auto;
        margin-top: 0;

        @include breakpoint(large up) {
          margin-top: -20px;
        }

        position: relative;

        p {
          color: $white;
        }

        .treatment-item {
          padding: 30px;
          padding-top: 20px;
          visibility: visible;

          @include transition(all 0.7s ease);

          opacity: 1;
          position: relative;
          top: 0;
          background-color: $gray-light;

          @include breakpoint(large up) {
            border-radius: 5px;
            visibility: hidden;
            position: absolute;
            opacity: 0;
            margin-bottom: rem-calc(350);
          }

          p {
            color: $black;
          }

          h1 {
            color: $black;
            font-weight: bold;
            text-align: center;
            margin-top: 0;
          }
        }
      }
    }

    .bodynavigation {
      display: none;

      @include breakpoint(large up) {
        display: flex;
      }
    }
  }
}
