#map {
  width: 100%;
  height: calc(50vh - #{$headersize});
  border-bottom: 2px solid $darkred;
}

.contact-row {
  @include flex-grid-row();

  .contact-left {
    @include flex-grid-column(12);
  }

  .contact-right {
    @include flex-grid-column(12);
  }

  @include breakpoint(large up) {
    .contact-left {
      @include flex-grid-column(6);
    }

    .contact-right {
      @include flex-grid-column(6);
    }
  }
}

.contactdetails {
  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;

      span {
        font-size: rem-calc(20);
        font-weight: bold;
      }

      i {
        margin-right: 10px;
      }

      a {
        text-decoration: none;
        color: $darkred;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.icons {
      margin-top: 20px;

      li {
        display: flex;
        align-items: center;
      }
    }
  }

  .questionnaire-link {
    padding: 10px 0 0;

    a {
      color: $white;
      padding: 10px;
    }
  }
}

.openinghours-header {
  margin-top: rem-calc(40);
}

.openinghours {
  p {
    margin: 0;
  }

  .day {
    p {
      font-weight: bold !important;
    }

    padding-right: rem-calc(20);
  }
}

.contact-right {
  .form-message {
    margin: 15px 0;
  }
}
