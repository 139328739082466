$white: #fefefe;
$black: #151e28;
$dark-gray: #2d2d2d;
$primary-color: #938641;
$primary-color-dark: #1a2930;
$secondary-color: #708238;
$menu-link-color: $black;
$success-color: #de902c;
$font-weight-bold: 400;
$body-font-family: 'Open Sans', sans-serif;
$footer-font-family: 'Open Sans', arial;
$menu-font-family: 'Didact Gothic', sans-serif;
$header-font-weight: $font-weight-bold;
$button-background-hover: $primary-color;
$button-background: $secondary-color;
$form-spacing: rem-calc(20);
$page-background-color: #c4e1f2;
$input-placeholder-color: $dark-gray;
$global-width: rem-calc(1200);
$gray: #4f4f4f;
$gray-lighter: #fafafa;
$gray-light: #e5e5e5;
$gray-dark: #2d2d2d;
$gray-primary: #585b59;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$breadcrumbs-item-font-size: 22;

// $darkred: #8b0000;

$darkred: #ff9e16;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1280px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 35px,
  medium: 40px,
  large: 45px
);
$grid-column-align-edge: true;
$block-grid-max: 8;

//color scheme
$color1: #ff9e16;
$color2: #ffa92f;
$color3: #006db2;
$color4: #16a5ff;

$colorshade1: #5f87b4;
$colorshade2: #3d5774;
$colorshade3: #1c2835;
$colorshade4: #1b2633;

$bgcolor: $white;
$footerbg: $black;
$menucolor: $black;
