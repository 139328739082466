.services-index-page {
  background-color: $black;
}

.service-page-wrapper {
  background-color: $gray-light;
}

.services-page {
  @include flex-grid-row();
  @include flex-align(center, top);

  position: relative;
  padding-bottom: rem-calc(60);
  padding-top: rem-calc(15);

  .service-container {
    @include flex-grid-column(12);

    @include breakpoint(medium) {
      @include flex-grid-column(6);
    }

    @include breakpoint(large up) {
      @include flex-grid-column(4);
    }

    padding-top: rem-calc(40);
    position: relative;

    a {
      text-decoration: none;
    }

    .service-wrapper {
      .service-image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: 0 auto;
        border: 3px solid $gray;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        -webkit-filter: grayscale(50%); /* Chrome, Safari, Opera */
        filter: grayscale(50%);

        @include transition(border-color 0.4s ease, filter 0.4s ease);
      }

      h1 {
        text-align: center;
        color: $gray-dark;
        font-weight: bold;
        font-size: rem-calc(18);
        line-height: 1.2em;

        @include transition(color 0.4s ease);
      }

      &:hover {
        .service-image {
          // border-color: $darkred;
          -webkit-filter: none; /* Chrome, Safari, Opera */
          filter: none;
        }

        h1 {
          color: $darkred;
        }
      }
    }
  }
}

.service-page {
  padding-bottom: rem-calc(250);

  .service-image {
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-bottom: 2px solid $darkred;
  }

  .service-container {
    @include flex-grid-row();

    .service-body {
      @include flex-grid-column(12);

      hr {
        border: 1px solid $gray-light;
        background-color: $gray-light;
      }

      .intro {
        p {
          line-height: 1.5em;
          font-size: rem-calc(18);

          @include breakpoint(large up) {
            font-size: rem-calc(20);
          }
        }
      }

      .block-paragraph {
        margin-bottom: rem-calc(40);

        ol {
          padding-left: rem-calc(0);
        }

        li {
          line-height: 1.5em;
          margin-bottom: rem-calc(10);
        }
      }

      .block-table {
        margin-bottom: rem-calc(40);

        table {
          margin: 0 auto;
          border-spacing: 0;

          td,
          th {
            vertical-align: top;
            padding: rem-calc(10);
            border-bottom: 1px solid $gray-light;
            line-height: 1.4em;
          }

          thead {
            tr {
              th {
                text-align: left;
                padding-right: rem-calc(10);

                @include breakpoint(large up) {
                  padding-right: rem-calc(40);
                }
              }
            }
          }

          tbody {
            tr {
              th {
                text-align: left;
                padding-right: rem-calc(10);

                @include breakpoint(large up) {
                  padding-right: rem-calc(40);
                }
              }
            }
          }
        }
      }
    }
  }

  .specialists-container {
    @include flex-grid-row();

    padding-top: rem-calc(30);

    .specialists-body {
      @include flex-grid-column(12);

      h2 {
        text-align: center;
        font-size: rem-calc(16);
        font-weight: bold;
        color: $gray;
      }

      hr {
        background-color: $gray-light;
        border: 1px solid $gray-light;
      }

      .specialists-list {
        @include flex-grid-row(nest);
        @include flex-align(center, middle);

        padding-top: rem-calc(20);

        .specialist {
          @include flex-grid-column(6);

          @include breakpoint(large up) {
            @include flex-grid-column(2);
          }

          .specialist-photo {
            width: 120px;
            height: 120px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            border-radius: 50%;
            border: 2px solid $gray-light;
            margin: 0 auto;
            -webkit-filter: grayscale(40%);
            filter: grayscale(40%);
          }

          h3 {
            text-align: center;
            font-size: rem-calc(16);
            font-weight: bold;
            color: $gray;
          }
        }
      }
    }
  }

  .testimonials {
    margin-top: rem-calc(40);

    .testimonials-header {
      @include flex-grid-row();

      .testimonials-header-body {
        @include flex-grid-column(12);

        h2 {
          text-align: center;
          font-size: rem-calc(16);
          font-weight: bold;
          color: $gray;
        }

        hr {
          background-color: $gray-light;
          border: 1px solid $gray-light;
        }
      }
    }

    .testimonial-nav {
      @include flex-grid-row();

      position: relative;
      // height: 10px;
      .nav-wrapper {
        margin: 0 auto;
      }

      .slider-next {
        display: inline-block;
        font-size: rem-calc(40);
        color: #717171;

        @include transition(all 0.4s ease);

        &:hover {
          cursor: pointer;
          color: $darkred;
        }
      }

      .slider-previous {
        display: inline-block;
        font-size: rem-calc(40);
        color: #717171;

        @include transition(all 0.4s ease);

        &:hover {
          cursor: pointer;
          color: $darkred;
        }
      }

      .dots-wrapper {
        display: inline-block;
        height: 40px;
        width: 200px;
        max-width: 500px;
        vertical-align: top;
      }

      .slideshow-dots {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        // // text-align: center;
        // bottom: unset;
        // top: unset;
        .dot {
          margin: 0 4px;
        }
      }
    }

    .testimonails-row {
      @include flex-grid-row();

      .testimonials-content {
        @include flex-grid-column(12);

        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);
      }
    }

    .testimonials-detail {
      position: relative;

      @include transition(height 0.4s ease);

      background-color: $gray-light;
      border-radius: 15px;
      height: 100px;
      overflow: hidden;

      .testimonial {
        opacity: 0;

        @include transition(all 0.4s ease);

        position: absolute;
        top: 0;

        &:first-child {
          opacity: 1;
        }

        .testimonial-wrapper {
          padding: rem-calc(20);
          border-radius: 15px;
        }

        .thetext {
          span {
            color: $darkred;
            display: block;
            width: 100%;
            font-size: rem-calc(34);

            &.last {
              text-align: right;
            }
          }

          p {
            margin-top: 0;
            font-style: italic !important;
            color: $gray;
            padding-left: rem-calc(30);
            padding-right: rem-calc(30);
          }
        }

        .thename {
          text-align: center;
          color: $darkred;
        }
      }
    }
  }
}
