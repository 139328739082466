.index-page-wrapper {
  // background-color: #30353e;
  background-color: $black;
  border-bottom: 2px solid $color2;

  .index-page-container {
    @include flex-grid-row();

    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);

    .index-page-header {
      @include flex-grid-column(12);

      h1 {
        color: $darkred;
        margin-bottom: 0;
      }
    }

    .index-page-intro {
      @include flex-grid-column(12);

      p {
        color: $gray-light;
      }
    }
  }
}
