footer.footer-class {
  position: absolute;
  bottom: 0;
  width: 100%;
  // padding: rem-calc(1);
  // border-top: 2px solid $gray-primary;
  background: $footerbg;

  .footer-container {
    @include flex-grid-row();
    // @include flex-align(center,middle);

    .footer-content {
      @include flex-grid-column(12);

      text-align: center;

      p {
        text-align: center;
        color: $gray-lighter;
      }

      a {
        font-size: rem-calc(16);
        color: $gray-lighter;
        margin: rem-calc(20);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .social {
        text-align: center;

        ul {
          padding: 0;
          width: 100%;
        }

        li {
          display: inline-block;
          color: $gray-light;
          font-size: rem-calc(28);
        }

        a {
          margin: rem-calc(20);
          color: $white;
          text-decoration: none;
          font-size: rem-calc(22);

          @include transition(color 0.5s ease);

          &:hover {
            color: $darkred;
          }
        }
      }
    }

    .devider {
      margin-bottom: rem-calc(20);

      hr {
        background-color: $darkred;
        border: 0.5px solid $darkred;
      }
    }
  }

  @include breakpoint(large up) {
    padding: rem-calc(0);

    .footer-container {
      padding: rem-calc(40);

      .copyright {
        color: $gray-light;

        p {
          font-size: rem-calc(12);
        }
      }
    }

    .devider {
      margin-bottom: rem-calc(40);
    }
  }
}
