.frontpage {
  background-color: $black;
  padding-bottom: rem-calc(270);

  @include breakpoint(large up) {
    padding-bottom: rem-calc(220);
  }
}

.frontpage-logo {
  @include flex-grid-row();
  @include flex-align(center,middle);

  text-align: center;
  padding: rem-calc(10) rem-calc(40) rem-calc(20) rem-calc(40);
  width: rem-calc(300);

  @include breakpoint(large up) {
    padding: 0;
  }
}

.main-frontpage-message {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;

  @include breakpoint(large up) {
    top: rem-calc(-122);
  }
}

.main-frontpage-message-wrapper {
  @include flex-grid-row();
  @include flex-align(center,middle);

  .main-frontpage-message-container {
    @include flex-grid-column(12);
    // padding-top: rem-calc(120);
    @include breakpoint(large up) {
      // margin-top: rem-calc(162);
      margin-bottom: rem-calc(50);
      padding-top: rem-calc(20);

      @include flex-grid-column(7);
    }

    text-align: center;
    padding-left: rem-calc(40) !important;
    padding-right: rem-calc(40) !important;

    p {
      display: block;
      position: relative;
      line-height: rem-calc(32);
      color: $black;

      span {
        height: 100%;
        display: inline-block;
        font-size: rem-calc(24);
        position: relative;
        top: rem-calc(-12);
      }
    }
  }
}
