header.header-class {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: $bgcolor;
  border-bottom: 2px solid $darkred;

  @include transition(background-color 0.7s ease);

  .header-container {
    @include flex-grid-row();

    height: rem-calc(70);

    .header-background {
      position: absolute;
      background-color: $bgcolor;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .header-logo {
      @include flex-grid-column(8);

      @include breakpoint(large up) {
        @include flex-grid-column(4);
      }

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: rem-calc(40);
        margin-bottom: 20px;
      }

      z-index: 3;
    }

    .header-menu {
      @include flex-grid-column(4);

      @include breakpoint(large up) {
        @include flex-grid-column(8);
      }

      display: flex;
      align-items: center;

      .hamburger {
        position: absolute;
        right: rem-calc(20);
        top: rem-calc(15);
        z-index: 10;
      }

      .row .three {
        padding: rem-calc(10) rem-calc(10);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #2c3e50;
        color: $black;
        text-align: center;
      }

      .hamburger .line {
        width: rem-calc(38);
        height: rem-calc(3);
        background-color: $black;
        display: block;
        margin: rem-calc(8) auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      .hamburger:hover {
        cursor: pointer;
      }

      /* ONE */

      #hamburger-1.is-active .line:nth-child(2) {
        opacity: 0;
      }

      #hamburger-1.is-active .line:nth-child(1) {
        -webkit-transform: translateY(rem-calc(11)) rotate(45deg);
        -ms-transform: translateY(rem-calc(11)) rotate(45deg);
        -o-transform: translateY(rem-calc(11)) rotate(45deg);
        transform: translateY(rem-calc(11)) rotate(45deg);
      }

      #hamburger-1.is-active .line:nth-child(3) {
        -webkit-transform: translateY(rem-calc(-11)) rotate(-45deg);
        -ms-transform: translateY(rem-calc(-11)) rotate(-45deg);
        -o-transform: translateY(rem-calc(-11)) rotate(-45deg);
        transform: translateY(rem-calc(-11)) rotate(-45deg);
      }

      ul {
        padding-left: 0;
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(30);
        -webkit-transition: top 0.7s ease-out;
        -moz-transition: top 0.7s ease-out;
        -o-transition: top 0.7s ease-out;
        transition: top 0.7s ease-out;
        position: absolute;
        top: rem-calc(-400);
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $bgcolor;
        z-index: 0;

        // min-height: 100%;

        li {
          display: block;
          margin: rem-calc(20);
          color: $menucolor;
          position: relative;

          a {
            font-size: rem-calc(18);
            font-family: 'Montserrat', sans-serif;
            // letter-spacing: 2px;
            // font-family: 'Fjalla One', sans-serif;
            color: $menucolor;
            text-decoration: none;
            text-transform: uppercase;

            @include transition(color 0.7 ease);
            // line-height: 46px;
          }

          .basketwrapper {
            position: relative;
            width: rem-calc(40);
            margin: 0 auto;
          }

          .basketcount {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $darkred;
            position: absolute;
            top: -10px;
            font-size: rem-calc(12);
            z-index: 9;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .underline {
            position: absolute;
            bottom: -10px;
            width: 0;
            height: 2px;
            background-color: $darkred;

            @include transition(width 0.7s ease);
          }

          &.selected {
            a {
              color: $darkred;
              font-weight: bold;
            }
          }

          &.highlighted {
            @include breakpoint(large up) {
              .underline {
                width: 100%;
              }
            }

            // a {
            //     color: $darkred;
            //     font-weight: normal;
            // }
          }

          @include breakpoint(large up) {
            &:hover {
              .underline {
                width: 100%;
              }
            }
          }
        }

        li.icon {
          margin-top: rem-calc(20);

          i {
            font-size: rem-calc(28);
          }
        }

        &:first-child {
          border-bottom: 2px solid $darkred;
        }
      }

      @include breakpoint(large up) {
        ul {
          li {
            a {
              font-size: rem-calc(18);
            }
          }

          &:first-child {
            border-bottom: none;
          }
        }
      }

      &.show {
        ul {
          top: rem-calc(50);
          left: 0;
        }
      }

      ul.submenumobile {
        position: relative;
        display: block;
        padding: 0;
        margin: 0;
        top: 0;
      }
    }
  }

  .header-submenu-wrapper {
    display: none;
  }

  @include breakpoint(large up) {
    .header-container {
      position: relative;
      margin-top: rem-calc(20);
      height: rem-calc(100);

      @include transition(all 0.4s ease);

      .header-background {
        background-color: transparent;
      }

      .header-logo {
        text-align: center;

        a {
          display: inline-block;
        }

        img {
          height: rem-calc(45);

          @include transition(all 0.4s ease);
        }
      }

      .header-menu {
        text-align: center;

        .hamburger {
          visibility: hidden;
        }

        ul {
          padding-top: rem-calc(0);
          padding-bottom: rem-calc(0);
          background-color: unset;
          z-index: 2;
          position: relative;
          top: 0;
          text-align: center;

          li {
            display: inline-block;
            margin: 0;
            margin-right: rem-calc(20);

            a {
              display: block;
              font-size: rem-calc(14);
              font-weight: 400;
            }

            &.icon {
              // width: rem-calc(40);
              text-align: center;
              margin: 0;

              i {
                font-size: rem-calc(22);

                @include transition(all 0.4s ease);

                position: relative;
                z-index: 10;
              }

              a {
                all: unset;
                cursor: pointer;
              }
            }

            i {
              &:hover {
                color: $darkred;
              }
            }
          }
        }

        ul.submenumobile {
          display: none;
        }
      }
    }

    &.shrink {
      background-color: rgba($bgcolor, 0.7);

      .header-container {
        height: rem-calc(70);

        .header-background {
          background-color: transparent;
        }

        .header-logo {
          img {
            height: rem-calc(35);
          }

          margin-bottom: rem-calc(15);
          margin-top: rem-calc(5);
        }

        .header-menu {
          ul {
            margin-bottom: rem-calc(7);
          }
        }
      }
    }
  }
}
