.team-index-page {
  background-color: $black;

  .team {
    padding-bottom: rem-calc(100);
    background-color: $white;

    .team-members {
      @include flex-grid-row();
      @include flex-align(center, top);

      padding-top: rem-calc(20);

      .team-member {
        @include flex-grid-column(12);

        @include breakpoint(medium) {
          @include flex-grid-column(6);
        }

        @include breakpoint(large up) {
          @include flex-grid-column(4);
        }

        padding-top: rem-calc(40);

        .team-member-photo {
          height: 150px;
          width: 150px;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          margin: 0 auto;
          border: 2px solid $gray-light;
          -webkit-filter: grayscale(20%); /* Chrome, Safari, Opera */
          filter: grayscale(20%);

          @include breakpoint(large up) {
            -webkit-filter: grayscale(50%); /* Chrome, Safari, Opera */
            filter: grayscale(50%);
          }

          @include transition(filter 0.7s ease);
        }

        .team-member-info {
          background-color: $gray-light;
          border-radius: 5px;
          margin: rem-calc(10);

          @include breakpoint(large up) {
            margin: rem-calc(40);
          }

          min-height: 130px;

          ul {
            list-style: none;
            padding: 0;
            text-align: center;
            height: 130px;
            width: 100%;
            display: block;
            padding-top: rem-calc(0);
            padding-bottom: rem-calc(0);
            margin: 0;

            li {
              color: $black;
              margin: 0;
              padding: 10px;

              p {
                margin: 0;
              }

              &.hr {
                padding: 0;
                margin-left: 10px;
                margin-right: 10px;
                height: 1px;

                hr {
                  margin: 0;
                  opacity: 0.7;
                  background-color: $gray-lighter;
                  border: 1px solid $gray-lighter;
                }
              }

              &.name {
                padding-bottom: 0;
                height: 40px;

                p {
                  font-weight: bold !important;
                }
              }

              &.title {
                padding-top: 0;
                padding-bottom: 0;
                height: 89px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                  font-size: rem-calc(12);
                  line-height: 1.2em;
                }
              }
            }
          }
        }

        .team-member-text {
          color: $gray-dark;
          margin-left: rem-calc(20);
          margin-right: rem-calc(20);

          @include breakpoint(large up) {
            margin-left: rem-calc(40);
            margin-right: rem-calc(40);
          }

          p {
            font-size: rem-calc(14);
          }

          ul {
            padding-left: 15px;
          }

          li {
            font-size: rem-calc(14);
            font-weight: bold;
          }
          // max-height: 0px;
          // overflow: hidden;
        }

        &:hover {
          .team-member-photo {
            -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
            filter: grayscale(0%);
          }
          // .team-member-text {
          //     max-height: 300px;
          // }
        }
      }
    }
  }
}
