









































































.video-js {
  display: flex;
  justify-content: center;
  align-items: center;
  .vjs-big-play-button {
    top: unset;
    left: unset;
  }

}
.video-block {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 50px 0;
}
.video-block::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.video-block .video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

