.article-page {
  background-color: $black;

  .article-intro-wrapper {
    // background-color: #30353e;
    background-color: $black;
    border-bottom: 2px solid $color2;
    margin-bottom: rem-calc(30);
  }

  .article-wrapper {
    padding-bottom: rem-calc(40);
    background-color: $white;

    .article-container {
      .article-header-container {
        @include flex-grid-row();

        padding-top: rem-calc(20);

        .article-header {
          @include flex-grid-column(12);

          h1 {
            color: $darkred;
            margin-bottom: 0;
          }
        }
      }

      .article-body-container {
        @include flex-grid-row();

        .article-body-section {
          @include flex-grid-column(12);

          .intro {
            p {
              color: $gray-light;
            }

            padding-bottom: rem-calc(20);
          }

          .section-body {
            color: $gray-dark;

            p {
              color: $gray;
            }

            img {
              margin-top: 5px;
              border: 1px solid $gray;
            }

            a {
              color: $darkred;
            }
          }

          .section-header {
            h2 {
              color: $gray;
            }

            hr {
              background-color: $darkred;
              border: 1px solid $darkred;
            }
          }
        }
      }
    }

    .imagefloatright {
      float: right;
    }
  }
}
